import React, { useEffect, useState } from "react";
import { Deposit } from "./Deposit";
import { Withdraw } from "./Withdraw";
import { Transfer } from "./Transfer";
import Parse from 'parse'
import "./tabs.css";
import { connect } from "react-redux";
import { setUserInternalWallet } from "redux/actions/userActions";
import { getInternalWalletBalances } from 'redux/actions/userActions'

const Wallet = Parse.Object.extend('Wallet')

const TabsComponent = (props) => {
    const [activeTab, setActiveTab] = useState("tab1");
    const { userHasWallet, setInternalWallet, getInternalBalances } = props
    useEffect(() => {
        initInternalWallet()

        // eslint-disable-next-line 
    }, [userHasWallet])


    const initInternalWallet = async () => {
        getInternalBalances()
        const query = new Parse.Query(Wallet)
        query.equalTo('user', Parse.User.current())
        const wallet = await query.first()
        if (wallet && !userHasWallet) {
            setInternalWallet(true)
        }
        else {
            setInternalWallet(false)
        }
    }

    return (
        <div className="Tabs">
            {/* Tab nav */}
            <ul className="nav-tabs">
                <TabNavItem
                    id="tab1"
                    title="Deposit"
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                />
                <TabNavItem
                    id="tab2"
                    title="Withdraw"
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                />
                <TabNavItem
                    id="tab3"
                    title="Transfer"
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                />
            </ul>
            <div className="outlet">
                <div className="outlet">
                    <TabContent id="tab1" activeTab={activeTab}>
                        <Deposit />
                    </TabContent>
                    <TabContent id="tab2" activeTab={activeTab}>
                        <Withdraw />
                    </TabContent>
                    <TabContent id="tab3" activeTab={activeTab}>
                        <Transfer />
                    </TabContent>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    userHasWallet: state.user.internalWallet
})

const mapDispatchToProps = (dispatch) => ({
    setInternalWallet: (bool) => dispatch(setUserInternalWallet(bool)),
    getInternalBalances: () => dispatch(getInternalWalletBalances()),
})

const Tabs = connect(mapStateToProps, mapDispatchToProps)(TabsComponent)
export { Tabs };




const TabNavItem = ({ id, title, activeTab, setActiveTab }) => {
    const handleClick = () => {
        setActiveTab(id);
    };
    return (
        <li onClick={handleClick} className={activeTab === id ? "active" : ""}>
            {title}
        </li>
    );
};

const TabContent = ({ id, activeTab, children }) => {
    return (
        activeTab === id ? <div className="TabContent">
            {children}
        </div>
            : null
    );
};