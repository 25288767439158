import Parse from "parse/lib/browser/Parse"
import { evolveContract, evolveContractAddress, bulkContractAddress } from 'smart-contract'

const ContractApproval = Parse.Object.extend('ContractApproval')
const Wallet = Parse.Object.extend('Wallet')

export const fetchUserParse = () => {
  const currentUser = Parse.User.current();
  return currentUser.fetch()
}

export const getUserBalanceParse = (account) => {
  if (account) {
    return evolveContract.methods.balanceOf(account).call()
  }
}

export const getWalletApproval = async (userCurrentWalletAddress) => {
  if (userCurrentWalletAddress) {
    const query = new Parse.Query(ContractApproval)
    query.equalTo('userWallet', userCurrentWalletAddress)
    query.equalTo('contract', evolveContractAddress)
    query.equalTo('spender', bulkContractAddress)
    const result = await query.first()
    if (result) return result
  }
}

export const getInternalWalletBalances = async () => {
  const query = new Parse.Query(Wallet)
  query.equalTo('user', Parse.User.current())
  const result = await query.first()
  if (result) return { availableBalance: result.get('availableBalance'), pendingBalance: result.get('pendingBalance'), inCompetitionBalance: result.get('inCompetitionBalance') }
}
