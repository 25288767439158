import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { WizardGameForm } from 'components';
import { getBnbPrice } from 'redux/actions/priceActions';
import { findGameById, fromWei, userHasRequiredItems, tokenIndexFinder } from 'utils/helper';
import { useParams } from 'react-router-dom';
import { web3Instance } from 'smart-contract/web3Instance';
import { Hash } from 'components/hash/Hash';
import { Stepper } from 'react-form-stepper';
import { getUserWalletApproval, setHasSufficientStableCoin } from 'redux/actions/userActions'
import { stableCoins } from 'smart-contract';
import { setStableCoin } from 'redux/actions/priceActions';
import { debounce } from "debounce";
import * as Parse from 'parse';
import { setIsTokenApproved } from 'redux/actions/approvalActions';
import { stableCoinsOnEOG_Competition } from 'smart-contract';
import Hypnosis from 'react-cssfx-loading/lib/Hypnosis'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'

const ContractApproval = Parse.Object.extend('ContractApproval')

export function PaymentModalComponent(props) {
  const {
    paymentDetails,
    userAccount,
    mode,
    tokenPrice,
    bnbPrice,
    getBnbPrice,
    user,
    isUserWalletApproved,
    getUserWalletApproval,
    userParseObject,
    preset,
    userWalletAddress,
    setHasSufficientStableCoin,
    setIsTokenApproved,
    isTokenApproved,
    setStableCoin,
  } = props;

  const [modalShow, setModalShow] = useState(false);
  const { gameId } = useParams()
  const gameAvatarHref = findGameById(+gameId).backgroundImageUrl
  const [approveTxFee] = useState(0)
  const [userHasKYC, setUserHasKYC] = useState(false)

  useEffect(() => {
    if (!bnbPrice) getBnbPrice()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (userAccount) getUserWalletApproval(userAccount)
    // calculateApproveTxFee()
    //eslint-disable-next-line
  }, [user, userAccount])

  useEffect(() => {
    if (userParseObject && userParseObject.get('KYCAt')) {
      setUserHasKYC(true)
    }
  }, [userParseObject])

  // const calculateApproveTxFee = async () => {
  //   if (userAccount) {
  //     try {
  //       const spender = EOG_ComptitionAddress;
  //       const currentStableCoinContract = selectedStableCoin.contract
  //       const approveMethod = await currentStableCoinContract.methods.approve(spender, toWei(approvalAmount))

  //       const gasPrice = await web3Instance.eth.getGasPrice()
  //       // const estimatedGas = await approveMethod.estimateGas({
  //       //   from: Web3.utils.toChecksumAddress(userAccount)
  //       // })
  //       setApproveTxFee(fromWei(gasPrice * estimatedGas))
  //     }
  //     catch (error) {
  //       console.log(error)
  //     }
  //   }
  // }

  // const showModalControl = async () => {
  //   if (preset.get("feeInUSD") === 0 &&
  //     currentUser.get("platforms")?.[platformTitle]?.games?.[gameId]?.userName) {
  //     try {
  //       await Parse.Cloud.run('start-playing', { preset_id: preset.id, wallet: userWalletAddress, balance: userBalance })
  //       navigate('/active-competition')
  //     }
  //     catch (error) {
  //       document.getElementById('scroll-info').scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" })
  //     }
  //   } else {
  //     setModalShow(true)
  //   }
  // }

  return (
    <>
      {paymentDetails.KYCRequired ?
        !userHasKYC ?
          <span className="text-white mt-3" type='button'>
            <div className="upcoming-table__tickets text-danger cursor-default">KYC Required </div>
          </span> :
          <span className="text-white mt-3" type='button' onClick={() => setModalShow(true)}>
            <div className="upcoming-table__tickets">{mode} {paymentDetails?.promotion === 'FREE_ENTRY' ? 'FREE' : ''} </div>
          </span>

        :

        (userParseObject?.get('level') < preset?.get('levelRequired')) ?
          <span className="text-white mt-3" type='button'>
            <Link to='/profile/account-status'>
              <div className="upcoming-table__tickets text-danger">
                <FontAwesomeIcon icon={faLock} size="sm" className='' /> &nbsp;
                {`Unlock at level ${preset?.get('levelRequired')}`} </div>
            </Link>
          </span> :
          <span className="text-white mt-3" type='button' onClick={() => setModalShow(true)}>
            <div className="upcoming-table__tickets">{mode} {paymentDetails?.promotion === 'FREE_ENTRY' ? 'FREE' : ''} </div>
          </span>
      }


      <PayModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        userAccount={userAccount}
        paymentDetails={paymentDetails}
        tokenPrice={tokenPrice}
        bnbPrice={bnbPrice}
        gameAvatarHref={gameAvatarHref}
        approveTxFee={approveTxFee}
        user={user}
        isUserWalletApproved={isUserWalletApproved}
        gameId={gameId}
        presetCurrency={preset.get("currency")}
        preset={preset}
        userWalletAddress={userWalletAddress}
        setHasSufficientStableCoin={setHasSufficientStableCoin}
        setIsTokenApproved={setIsTokenApproved}
        isTokenApproved={isTokenApproved}
        setStableCoin={setStableCoin}
      />
    </>
  );
}

function PayModal(props) {
  const [showDetails, setShowDetails] = useState(false);
  const [gameHasRequirement, setGameHasRequirement] = useState(false)

  const {
    fee,
    players,
    rake
  } = props.paymentDetails
  const {
    userAccount,
    tokenPrice,
    onHide,
    bnbPrice,
    gameId,
    gameAvatarHref,
    paymentDetails,
    approveTxFee,
    user,
    isUserWalletApproved,
    show,
    presetCurrency,
    setHasSufficientStableCoin,
    setIsTokenApproved,
    userWalletAddress,
    preset,
    isTokenApproved,
    setStableCoin,
  } = props

  const batchTxFEE = 0.55 * 1.5
  const [networkTxFee, setNetworkTxFee] = useState()
  const [step, setStep] = useState(0)
  const [selectedCoin, setSelectedCoin] = useState(null)
  const [submittedSelectedCurrency, setSubmittedSelectedCurrency] = useState(null)
  const [stableCoinAmount, setStableCoinAmount] = useState(null)
  const [tokenIndex, setTokenIndex] = useState(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    calculateIndividualTxFee()
    setGameHasRequirement(userHasRequiredItems(user, gameId))
    if (!userAccount) setStep(0)
    if (userAccount && presetCurrency === "STABLE_COINS" && !submittedSelectedCurrency) setStep(1)
    if (userAccount && !isUserWalletApproved && presetCurrency === "STABLE_COINS" && gameHasRequirement && submittedSelectedCurrency && !isTokenApproved) setStep(2)
    if (userAccount && gameHasRequirement && submittedSelectedCurrency && presetCurrency === "STABLE_COINS" && isTokenApproved) setStep(3)
    return () => {
      setGameHasRequirement({});
    };
    // eslint-disable-next-line
  }, [gameId, showDetails, networkTxFee, user, isUserWalletApproved, submittedSelectedCurrency, isTokenApproved])

  useEffect(() => {
    if (selectedCoin && selectedCoin.symbol) checkCurrentTokenApproval(selectedCoin.symbol)
    if (userWalletAddress) {
      web3Instance.eth.getBalance(userWalletAddress).then(() => {
        checkUserStableCoinAmount(selectedCoin?.symbol)
      });
    }
    //eslint-disable-next-line
  }, [userWalletAddress, selectedCoin])

  useEffect(() => {
    if (selectedCoin && !stableCoinAmount && stableCoinAmount !== 0) {
      setLoading(true)
    } else {
      setLoading(false)
    }
  }, [stableCoinAmount, selectedCoin])

  const toggleDetails = async () => {
    await setShowDetails(!showDetails)
    if (!showDetails) {
      const list = document.getElementById("list");
      const lastChild = list.lastChild;
      lastChild.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" })
    }
  }

  const calculateIndividualTxFee = async () => {
    try {
      // Estimated gas calculated once
      const estimateGas = 36341
      const gasPrice = await web3Instance.eth.getGasPrice()
      const transactionFee = fromWei((gasPrice * estimateGas))
      setNetworkTxFee(+transactionFee)
    } catch (error) {
      console.log(error);
    }
  }

  const checkUserStableCoinAmount = (wantedStableCoin) => {
    if (userWalletAddress) {
      const debounceCheck = debounce(async () => {
        try {
          const result = await selectedCoin.contract.methods.balanceOf(userWalletAddress).call()
          const userStableCoinBalance = +fromWei(result)
          if (userStableCoinBalance || userStableCoinBalance === 0) setStableCoinAmount(userStableCoinBalance)
          if (+userStableCoinBalance >= +wantedStableCoin && +wantedStableCoin !== 0) {
            setHasSufficientStableCoin(true)
          }
          else {
            setHasSufficientStableCoin(false)
          }
        } catch (error) {
          console.log(error)
        }
      }, 300)
      debounceCheck()
    }
  }

  const handleChangeStableCoin = async (e) => {
    const stableCoin = e.target.value
    const currentTokenIndex = tokenIndexFinder(e.target.value)
    if (currentTokenIndex) setTokenIndex(currentTokenIndex.index)
    await setStableCoinInStates(stableCoin)
    checkCurrentTokenApproval(stableCoin)
  }

  const setStableCoinInStates = async (symbol) => {
    const coin = stableCoins.find(coin => coin.symbol === symbol)
    await setStableCoin(coin) //set in redux for unmounted modal
    await setSelectedCoin(coin)
    setStableCoinAmount(null)
  }

  const checkCurrentTokenApproval = async (stableCoin) => {
    try {
      const currentStableCoinDetails = stableCoins.find(coin => coin.symbol === stableCoin)
      const stableCoinContractAddress = currentStableCoinDetails.contract._address
      const query = new Parse.Query(ContractApproval)
      query.equalTo('userWallet', userWalletAddress)
      query.equalTo('contract', stableCoinContractAddress)
      const currentTokenApproval = await query.first()
      if (currentTokenApproval) setIsTokenApproved(true)
      else setIsTokenApproved(false)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      contentClassName='modal-background-dark'
      className="pb-5"
    >
      <Modal.Header className={`btn text-right text-white modal-background-dark cursor-default`}>
        <button className=' text-center border-0 ' style={{ backgroundColor: '#5e627e' }} onClick={onHide}><span className='font-weight-bold'>×</span></button>
      </Modal.Header>
      <Stepper
        steps={[{ label: 'Player Account' }, { label: 'Select Token' }, { label: 'Approve' }, { label: 'Confirm' }]}
        activeStep={step}
        connectorStateColors={true}
        connectorStyleConfig={{
          disabledColor: '#555555',
          activeColor: '#dfffad',
          completedColor: '#dfffad'
        }}
        className={'stepper-title'}
        styleConfig={{
          activeBgColor: '#fff',
          completedBgColor: '#a3ff12',
          inactiveBgColor: '#fff',
          activeTextColor: '#000',
          completedTextColor: '#000',
          inactiveTextColor: '#000',
          fontWeight: '700',
        }}
      />

      {step === 1 &&
        <Modal.Body className={`d-flex justify-content-center modal-background-dark`}>
          <div className="site-content__center text-center ">
            <p className={`col primary-green font-16 mb-4 py-2 swap-border`}  >
              <span className={`primary-green  brighten-on-hover`} >
                {selectedCoin?.symbol} Balance  ≃ <span className='font-weight-bold' >
                  {(stableCoinAmount || stableCoinAmount === 0) && Math.round(stableCoinAmount * 1000) / 1000}
                </span>
              </span>

            </p>
            {loading &&
              <div className="d-flex flex-column justify-content-center align-items-center mb-4">
                <Hypnosis color="#a3ff12" width="40px" height="40px" duration="2s" />
              </div>
            }
            <div className='col-12' >
              <select
                defaultValue={"DEFAULT"}
                className="w-60 form-select-lg py-2 mx-auto ml-3 font-weight-bold text-white "
                style={{ backgroundColor: '#151720' }}
                aria-label="form-select-lg example"
                onChange={handleChangeStableCoin}
              >
                <option value="DEFAULT" disabled>Select Token</option>
                {
                  stableCoinsOnEOG_Competition.map(token => {
                    return (
                      <option value={token.tokenName} key={token.index}>{token.tokenName}</option>
                    )
                  })
                }
              </select>
            </div>
            {stableCoinAmount >= preset.get("feeInUSD") &&
              (
                <div>

                </div>
              )}
            {stableCoinAmount < preset.get("feeInUSD") && selectedCoin && !loading &&
              (
                <div className='my-5 font-weight-bold  text-white '>
                  You don't have <br />sufficient balance
                </div>
              )}
          </div>
        </Modal.Body>
      }
      {gameHasRequirement &&
        <Modal.Body className={`d-flex justify-content-center modal-background-dark`}>
          <main className=" site-content text-center col-10 mb-5 " >
            <div className="site-content__center">
              {step === 2 &&
                <>
                  <div className="match-stats-widget match-stats-widget--general my-3">
                    <ul className="match-stats-widget__body" id='list'>
                      <li>
                        <figure className="match-team match-team--winner" role="group">
                          <figure
                            className="match-team-logo match-team-logo--team-1"
                            role="group">
                            <img
                              style={{ width: '100px' }}
                              src={gameAvatarHref}
                              srcSet={gameAvatarHref}
                              alt="Logo"
                            />
                          </figure>
                        </figure>
                      </li>
                      <li className='py-2'>
                        <span className='payment-title' > Your Address</span>
                        <span className='font-weight-light'>
                          [ {userAccount && userAccount} ]
                        </span>
                      </li>

                      <li className='py-2'>
                        <span className='text-capitalize payment-title '>
                          Approve Transaction Fee
                        </span>
                        {approveTxFee ? approveTxFee : ' '} BNB
                      </li>
                    </ul>
                  </div>
                </>
              }
              {step === 3 &&
                <>
                  <div className="match-stats-widget match-stats-widget--general my-3">
                    <ul className="match-stats-widget__body" id='list'>
                      <li>
                        <figure className="match-team match-team--winner" role="group">
                          <figure
                            className="match-team-logo match-team-logo--team-1"
                            role="group">
                            <img
                              style={{ width: '100px' }}
                              src={gameAvatarHref}
                              srcSet={gameAvatarHref}
                              alt="Logo"
                            />
                          </figure>
                        </figure>
                      </li>
                      <li className='py-2'>
                        <span className='payment-title' > Your Address</span>
                        <span className='font-weight-light'>
                          {
                            !showDetails && <> [ <Hash hash={userAccount} shorten={5} mode='address' /> ] </>
                          }
                          {
                            showDetails && <> [ {userAccount && userAccount} ] </>
                          }
                        </span>
                      </li>
                      <li className='py-2'>
                        <span className='payment-title' >Competitors</span>
                        <span>
                          {players}v{players}
                        </span>
                      </li>
                      <li className='py-2'>
                        <span className='text-left text-capitalize payment-title' >
                          compete to earn (after fees)
                          {
                            showDetails &&
                            <>
                              <br />
                              <span className='text-success text-capitalize payment-subtitle'  >
                                Amount of credits you will receive in your EOG Account if you win this competition
                              </span>
                            </>
                          }
                        </span>
                        <span  >
                          {rake ? (fee * 2) - (rake * 2) : (fee * 2)} USD
                          {
                            showDetails &&
                            <span className="text-success payment-subtitle" >
                              {tokenPrice ? ((fee * 2) / tokenPrice).toFixed(6) : '...'} STABLE COINS
                            </span>
                          }
                        </span>
                      </li>
                      <li className='py-2'>
                        <span className='text-capitalize payment-title '>
                          Competition Entry Fee
                        </span>
                        {
                          showDetails && paymentDetails?.promotion === 'FREE_ENTRY' &&
                          <span>
                            0 USD
                            <span className="text-success payment-subtitle" >
                              0 STABLE COINS
                            </span>
                          </span>
                        }
                        {
                          showDetails && paymentDetails?.promotion !== 'FREE_ENTRY' &&
                          <span>
                            {fee} USD
                            <span className="text-success payment-subtitle" >
                              {tokenPrice ? (fee / tokenPrice).toFixed(6) : '...'} STABLE COINS
                            </span>
                          </span>
                        }
                        {
                          !showDetails && paymentDetails?.promotion === 'FREE_ENTRY' &&
                          <span className=' text-white' style={{ fontSize: '22px' }}>
                            0 USD
                            <span className="text-success payment-subtitle" >
                            </span>
                          </span>
                        }
                        {
                          !showDetails && paymentDetails?.promotion !== 'FREE_ENTRY' &&
                          <span className=' text-white' style={{ fontSize: '22px' }}>
                            <img src='/assets/img/tilde.png' alt='price' style={{ width: '15px', filter: ' invert(100%)' }} /> &nbsp;{(fee + (batchTxFEE / (players * 2))).toFixed(2)} USD
                            <span className="text-success payment-subtitle" >
                            </span>
                          </span>
                        }
                      </li>
                      <li className='py-2'>
                        <span className='text-left text-capitalize payment-title' >Admin Fee </span>
                        {
                          showDetails && paymentDetails?.promotion === 'FREE_ENTRY' &&
                          <span>
                            0 USD
                            <span className="text-success payment-subtitle" >
                              0 STABLE COINS
                            </span>
                          </span>
                        }
                        {
                          showDetails && paymentDetails?.promotion !== 'FREE_ENTRY' &&
                          <span>
                            {rake} USD
                            <span className="text-success payment-subtitle" >
                              {tokenPrice ? (rake / tokenPrice).toFixed(6) : '...'} STABLE COINS
                            </span>
                          </span>
                        }
                        {
                          !showDetails && paymentDetails?.promotion !== 'FREE_ENTRY' &&
                          <span className=' text-white' >
                            {rake} USD
                            <span className="text-success payment-subtitle" >
                            </span>
                          </span>
                        }
                      </li>
                      {
                        showDetails &&
                        <>
                          <li className='py-2'>
                            <span className='text-left text-capitalize payment-title' >
                              Network Transaction Fee for an Individual
                              <br />
                              <span className='text-success text-capitalize payment-subtitle'  >
                                not applied  (for comparison only)
                              </span>
                            </span>
                            <span>
                              <img src='/assets/img/tilde.png' alt='price' style={{ width: '15px', filter: ' invert(100%)' }} /> &nbsp;
                              {bnbPrice ? (networkTxFee * bnbPrice * 2).toFixed(2) : '...'} USD
                              <span className="text-success payment-subtitle" >
                                {bnbPrice ? networkTxFee : '...'} BNB
                              </span>
                            </span>
                          </li>
                          <li className='py-2'>
                            <span className='text-left  text-capitalize payment-title'  >
                              Network Transaction Fee When Processed Together (Batch)
                              <br />
                              <span className='text-success  text-capitalize  payment-subtitle'  >
                                A Single Transaction to collect entrance fee from {players * 2} Competitors
                              </span>
                            </span>
                            <span >
                              <img src='/assets/img/tilde.png' alt='price' style={{ width: '15px', filter: ' invert(100%)' }} />  &nbsp;  {batchTxFEE.toFixed(2)} USD
                              <span className="text-success payment-subtitle" >
                                {bnbPrice ? (batchTxFEE / bnbPrice).toFixed(6) : '...'} BNB
                              </span>
                            </span>
                          </li>
                          <li className='py-2'>
                            <span className='text-left text-capitalize payment-title' >
                              Your share of the batch Network Transaction Fee
                              <br />
                              <span className='text-success text-capitalize payment-subtitle'  >
                                1/{players * 2} of the batch Transaction Fee
                              </span>
                            </span>
                            {paymentDetails?.promotion === 'FREE_ENTRY' ?
                              <span>
                                &nbsp; 0 USD
                                <span className="text-success payment-subtitle" >
                                  0 BNB
                                </span>
                              </span> :
                              <span>
                                <img src='/assets/img/tilde.png' alt='price' style={{ width: '15px', filter: ' invert(100%)' }} />  &nbsp; {(batchTxFEE / (players * 2)).toFixed(2)} USD
                                <span className="text-success payment-subtitle" >
                                  {bnbPrice ? (((batchTxFEE / (players * 2)) / bnbPrice)).toFixed(6) : '...'} BNB
                                </span>
                              </span>}

                          </li>
                          <li className='py-2'>
                            <span className='text-left text-capitalize' >
                              Payable
                              <br />
                              <span className='text-success text-capitalize payment-subtitle '  >
                                Your entrance fee + your share of the batch net. tx. fee
                              </span>
                            </span>
                            {paymentDetails?.promotion === 'FREE_ENTRY' ?
                              <span style={{ fontSize: '22px' }}>
                                &nbsp; 0 USD
                                <>
                                  <span className="text-success payment-subtitle " >
                                    0 BNB
                                  </span>
                                  <span className="text-success payment-subtitle" >
                                    0 STABLE COINS
                                  </span>
                                </>
                              </span> :
                              <span style={{ fontSize: '22px' }}>
                                <img src='/assets/img/tilde.png' alt='price' style={{ width: '15px', filter: ' invert(100%)' }} />
                                &nbsp; {(fee + (batchTxFEE / (players * 2))).toFixed(2)} USD
                                <>
                                  <span className="text-success payment-subtitle " >
                                    {(fee / tokenPrice).toFixed(6)} STABLE COINS + {((batchTxFEE / (players * 2)) / bnbPrice).toFixed(6)} BNB
                                  </span>
                                  <span className="text-success payment-subtitle" >
                                    {tokenPrice ? ((fee + (batchTxFEE / (players * 2))) / tokenPrice).toFixed(6) : '...'} STABLE COINS
                                  </span>
                                </>
                              </span>
                            }
                          </li>
                        </>
                      }
                      <li className='py-2'>
                        <span className='text-left text-capitalize' >
                        </span>
                        <span style={{ fontSize: '28px' }}>
                          {
                            <button
                              onClick={toggleDetails}
                              className="btn btn-link  font-weight-bold pr-0"
                              style={{ fontSize: '16px' }}>
                              {showDetails ? 'Hide details ▲' : 'Show details ▼'}
                            </button>
                          }
                        </span>
                      </li>
                    </ul>
                  </div>
                </>
              }
            </div>
            {step !== 1 &&
              <div>

              </div>
            }
          </main>
        </Modal.Body>
      }
      {
        !gameHasRequirement &&
        <Modal.Body className={`d-flex justify-content-center modal-background-dark`}>
          <main className=" site-content text-center col-10 mb-5 " >
            <div className="site-content__center ">
              <div className="match-stats-widget match-stats-widget--general my-3">
                <ul className="match-stats-widget__body" id='list'>
                  <li>
                    <figure className="match-team match-team--winner" role="group">
                      <figure
                        className="match-team-logo match-team-logo--team-1"
                        role="group">
                        <img
                          style={{ width: '100px' }}
                          src={gameAvatarHref}
                          srcSet={gameAvatarHref}
                          alt="Logo"
                        />
                      </figure>
                    </figure>
                  </li>
                </ul>
                <WizardGameForm gameId={gameId} ready={(bool) => {
                  setGameHasRequirement(bool);
                  if (bool && !isUserWalletApproved) setStep(2)
                  if (bool && (isUserWalletApproved || paymentDetails.promotion === 'FREE_ENTRY')) setStep(3)
                }}
                />
              </div>
            </div>
          </main>
        </Modal.Body>
      }
    </Modal>
  );
}

const mapStateToProps = (state) => {
  return {
    tokenPrice: state.config?.['token-price'],
    userAccount: state.user.userAccount,
    bnbPrice: state.price.bnbPrice,
    user: state.user.userParseObject,
    pubgUsername: state.user.userParseObject?.get('platforms')?.steam?.games?.['10906']?.userName,
    isUserWalletApproved: state.user.isUserWalletApproved,
    userParseObject: state.user.userParseObject,
    config: state.config,
    userWalletAddress: state.user.userAccount,
    userBalance: state.user.userBalance,
    isTokenApproved: state.approval.tokenApproval,
    selectedStableCoin: state.price.stableCoin,
  }
}

const mapDispatchtoProps = (dispatch) => {
  return {
    getBnbPrice: () => dispatch(getBnbPrice()),
    getUserWalletApproval: (currentWalletAddress) => dispatch(getUserWalletApproval(currentWalletAddress)),
    setStableCoin: (coin) => dispatch(setStableCoin(coin)),
    setHasSufficientStableCoin: (bool) => dispatch(setHasSufficientStableCoin(bool)),
    setIsTokenApproved: (bool) => dispatch(setIsTokenApproved(bool))
  }
}

const PaymentModal = connect(mapStateToProps, mapDispatchtoProps)(PaymentModalComponent)
export { PaymentModal }
