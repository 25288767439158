import React from 'react'
import { Preloader, SvgColorFilter } from 'components'
import { GamesLayout } from 'layouts'
import { Helmet } from 'react-helmet'
import { Tabs } from './components/Tabs';

function Ledger() {
    return (
        <GamesLayout>
            <Helmet>
                <title>EVOLVE - My Account</title>
            </Helmet>
            <Tabs />
            <Preloader />
            <SvgColorFilter />
        </GamesLayout>
    )
}

export { Ledger }