import React from 'react'

function InternalWalletBalances({ availableBalance, pendingBalance, inCompetitionBalance }) {
    return (
        <div className="balance-container">
            <div>
                <h6 className="balance-title">PENDING BALANCE</h6>
                <div className="pending-balance-amount">${pendingBalance.toFixed(2)}</div>
            </div>
            <div className='available-balance-container'>
                <h4 className="balance-title">AVAILABLE BALANCE</h4>
                <div className="available-balance-amount">${availableBalance.toFixed(2)}</div>
            </div>

            <div>
                <h6 className="balance-title">IN COMPETITION BALANCE</h6>
                <div className="in-competition-balance-amount">${inCompetitionBalance.toFixed(2)}</div>
            </div>
        </div>
    )
}

export { InternalWalletBalances }