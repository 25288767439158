// USER ACTIONS
export const FETCH_CURRENT_USER = "fetch_current_user"
export const FETCH_CURRENT_USER_ERROR = "fetch_current_user_error"
export const GET_USER_BALANCE = "get_user_balance"
export const GET_USER_BALANCE_ERROR = "get_user_balance_error"
export const GET_USER_FROM_LOCAL = "get_user_from_local"
export const GET_USER_FROM_LOCAL_ERROR = "get_user_from_local_error"
export const GET_USER_ACCOUNT_ADDRESS = "get_user_account_address"
export const GET_USER_ACCOUNT_ADDRESS_ERROR = "get_user_account_address_error"
export const SET_USER_ACCOUNT_ADDRESS = "set_user_account_address"
export const SET_USER_ACCOUNT_ADDRESS_ERROR = "set_user_account_address_error"
export const SET_HAS_SUFFICIENT_STABLE_COIN = "set_has_sufficient_stable_coin"
export const SET_USER_WALLET_APPROVAL = "set_user_wallet_approval"
export const SET_USER_WALLET_APPROVAL_ERROR = "set_user_wallet_approval_error"
export const SET_USER_INTERNAL_WALLET = "set_user_internal_wallet"
export const GET_INTERNAL_WALLET_BALANCE = "get_internal_wallet_balance"

// PRICE ACTIONS
export const SET_TOKEN_PRICE = 'set_token_price'
export const GET_BNB_PRICE = 'get_bnb_price'
export const GET_BNB_PRICE_ERROR = 'get_bnb_price_error'
export const SET_SWAP_AMOUNTS = 'set_swap_amounts'
export const SET_STABLE_COIN = 'set_stable_coin'

// APPROVAL ACTIONS
export const SET_APPROVED_TOKENS = 'set_approved_tokens'
export const SET_IS_TOKEN_APPROVED = 'set_is_token_approved'

// PAYMENT ACTION
export const SET_SHOW_PAYMENT_MODAL = "set_show_payment_modal"
export const SET_PAYMENT_DETAILS = "set_payment_details"

// CONFIG ACTIONS
export const GET_CONFIG = "get_config"
export const GET_CONFIG_ERROR = "get_config_error"
export const SET_CONFIG = "set_config"
export const SET_CONFIG_ERROR = "set_config_error"
export const SET_CONFIG_CHANGES = "set_config_changes"
export const SET_CONFIG_CHANGES_ERROR = "set_config_changes_error";
export const SET_LOADING = "set_loading"

//GAME ACTIONS
export const SET_ACTIVE_GAMES = "set_active_games"
export const SET_ACTIVE_GAMES_ERROR = "get_active_games_error"
export const ADD_ACTIVE_GAME = "add_active_game"
export const ADD_ACTIVE_GAME_ERROR = "add_active_game_error"
export const REMOVE_ACTIVE_GAME = "remove_active_game"
export const REMOVE_ACTIVE_GAME_ERROR = "remove_active_game_error";
export const SET_PRESET_ID = "set_preset_id"

//PRESET ACTIONS
export const SET_PRESETS = "set_presets"
export const SET_PRESETS_ERROR = "set_presets_error"
export const ADD_ACTIVE_PRESET = "add_active_preset"
export const ADD_ACTIVE_PRESET_ERROR = "add_active_preset_error"
export const REMOVE_ACTIVE_PRESET = "remove_active_preset"
export const REMOVE_ACTIVE_PRESET_ERROR = "remove_active_preset_error";
