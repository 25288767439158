import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import { Stepper } from 'react-form-stepper'
import { GameRequirementChecker } from './components/GameRequirementChecker'
//import { TokenSelector } from './components/TokenSelector'
import  StartCompetition  from './components/StartCompetition'
import { connect } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { userHasRequiredItems } from 'utils/helper'
import Parse from 'parse'
import { useAccount } from 'wagmi'

function JoinGameModalComponent({ userWalletAddress, preset, user }) {
  const [currentStep, setCurrentStep] = useState(0)
  const [tokenBlockchainIndex, setTokenBlockchainIndex] = useState()
  const [tokenBalance, setTokenBalance] = useState()
  const [show, setShow] = useState(false)
  const navigate = useNavigate()
  const { gameId } = useParams()
  const { address: account } = useAccount()

  const freeTrialControl = async () => {
    if (userHasRequiredItems(user, gameId)) {
      if (preset.get('promotion') === 'FREE_ENTRY') {
        setShow(true)
        if (account) {
          setCurrentStep(2)
        }
      } else if (preset.get('promotion') !== 'FREE_ENTRY' && preset.get('feeInUSD') === 0 && userHasRequiredItems(user, gameId)) {
          try {
            await Parse.Cloud.run('start-playing', {
              preset_id: preset.id + '',
              wallet: null,
              balance: null,
              selectedStableCoin: null,
            })
            navigate('/active-competition')
          } catch (error) {
            setShow(true)
            setCurrentStep(2)
            document
              ?.getElementById('scroll-info')
              ?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
          } 
      } else {
        setShow(true)
      }
    } else {
      setShow(true)
    }
  }

  useEffect(() => {
    if (show && userHasRequiredItems(user, gameId) && preset.get('promotion') === 'FREE_ENTRY' && account) {
      setCurrentStep(2)
    }
    //eslint-disable-next-line
  }, [account])

  useEffect(() => {
    return () => {
      setCurrentStep(0)
    }
  }, [])

  const stepComponents = [GameRequirementChecker, StartCompetition]

  const ThisStepComponent = stepComponents[currentStep]

  const nextStep = () => {
    setCurrentStep(currentStep + 1)
  }

  const backStep = () => {
    if (preset.get('promotion') === 'FREE_ENTRY') {
      setCurrentStep(currentStep + 1)
    } else if (preset.get('promotion') !== 'FREE_ENTRY' && preset.get('feeInUSD') !== 0) {
      setCurrentStep(currentStep + 1)
    } else {
      setCurrentStep(currentStep)
    }
    freeTrialControl()
  }

  const onHide = () => {
    setShow(false)
    setCurrentStep(0)
  }
  return (
    <>
      <span className="text-white mt-3" type="button" onClick={() => freeTrialControl()}>
        <div className="upcoming-table__tickets">JOIN</div>
      </span>

      <Modal
        show={show}
        onHide={onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        contentClassName="modal-background-dark"
        className="pb-5">
        <Modal.Header className={`btn text-right text-white modal-background-dark cursor-default`}>
          <button className=" text-center border-0 " style={{ backgroundColor: '#5e627e' }} onClick={onHide}>
            <span className="font-weight-bold">×</span>
          </button>
        </Modal.Header>
        <Stepper
          steps={[{ label: 'Player Account' }, { label: 'Confirm' }]}
          activeStep={currentStep}
          connectorStateColors={true}
          connectorStyleConfig={{
            disabledColor: '#555555',
            activeColor: '#dfffad',
            completedColor: '#dfffad',
          }}
          className={'stepper-title'}
          styleConfig={{
            activeBgColor: '#fff',
            completedBgColor: '#a3ff12',
            inactiveBgColor: '#fff',
            activeTextColor: '#000',
            completedTextColor: '#000',
            inactiveTextColor: '#000',
            fontWeight: '700',
          }}
        />
        <Modal.Body className={`d-flex justify-content-center align-items-center modal-background-dark`}>
          <ThisStepComponent
            tokenBalance={tokenBalance}
            setTokenBalance={setTokenBalance}
            tokenBlockchainIndex={tokenBlockchainIndex}
            setTokenBlockchainIndex={setTokenBlockchainIndex}
            nextStep={nextStep}
            backStep={backStep}
            userWalletAddress={userWalletAddress}
            preset={preset}
            user={user}
          />
        </Modal.Body>
      </Modal>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    user: state.user.userParseObject,
    userWalletAddress: state.user.userAccount,
  }
}

const JoinGameModal = connect(mapStateToProps)(JoinGameModalComponent)
export { JoinGameModal }
