import React, { useEffect, useRef, useState } from 'react'
import Parse from 'parse'
import { connect } from 'react-redux'
import { getInternalWalletBalances } from 'redux/actions/userActions'
import { Button } from 'components';
import { customToast } from 'utils/helper';
import { InternalWalletBalances } from './InternalWalletBalance';
import { Link } from 'react-router-dom';


function WithdrawComponent(props) {
    const { availableBalance, pendingBalance, inCompetitionBalance, userHasWallet, getInternalBalances } = props
    const [amount, setAmount] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const inputRef = useRef(null);

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.value = availableBalance;
            setAmount(availableBalance)
            inputRef.current.focus();
        }
    }, [availableBalance, pendingBalance, inCompetitionBalance])


    const handleWithdrawClick = async () => {
        setIsLoading(true)
        try {
            await Parse.Cloud.run('withdraw', { amount: Number(amount) })
            getInternalBalances()
            setAmount(null)
            customToast('Withdrawal successful', 'success')
        } catch (error) {
            customToast(error.message, 'danger')
            console.log(error)
        }
        setIsLoading(false)
    }

    return (
        <div>
            {
                userHasWallet ?
                    <div className="deposit-container">
                        <InternalWalletBalances availableBalance={availableBalance} pendingBalance={pendingBalance} inCompetitionBalance={inCompetitionBalance} />
                        <p className='withdraw-input-label'>Enter the amount you want to withdraw</p>
                        <div className="withdraw-input-container">
                            <input ref={inputRef} type="number" className='custom-withdraw-input' value={amount || ''} onChange={(e) => setAmount(e.target.value)} />
                        </div>

                        <Button className="withdraw-btn" onClick={handleWithdrawClick} disabled={(amount > availableBalance || !availableBalance || isLoading) ? true : false}>
                            {
                                isLoading ? 'Loading...' :
                                    amount > availableBalance ? 'Insufficient funds' : 'Withdraw'
                            }
                        </Button>
                        <p className="text-white font-16">Go to <Link to={'/my-account/transactions'}>Transaction history</Link> </p>
                    </div>
                    :
                    <div className="Deposit__no-wallet">
                        <p>You don't have a wallet</p>
                    </div>
            }
        </div>

    );
}

const mapStateToProps = (state) => ({
    userHasWallet: state.user.userInternalWallet,
    availableBalance: state.user.availableBalance,
    pendingBalance: state.user.pendingBalance,
    inCompetitionBalance: state.user.inCompetitionBalance,
})

const mapDispatchToProps = (dispatch) => ({
    getInternalBalances: () => dispatch(getInternalWalletBalances())
})

const Withdraw = connect(mapStateToProps, mapDispatchToProps)(WithdrawComponent)
export { Withdraw }