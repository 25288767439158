import React, { useState, useEffect } from 'react'
//import { Hash } from 'components/hash/Hash';
import { connect } from 'react-redux'
import { Link, useParams } from 'react-router-dom';
import { findGameById } from 'utils/helper';
import { useNavigate } from 'react-router-dom';
import { Button } from 'components'
import Parse from 'parse';
import { useAccount } from 'wagmi';
import { toWei, fromWei } from 'web3-utils';
import { getInternalWalletBalances } from 'redux/actions/userActions'

function StartCompetition({ preset, tokenBlockchainIndex, tokenBalance, availableBalance, getInternalBalances }) {
  const [isAllowedToStartNewCompetition, setIsAllowedToStartNewCompetition] = useState(true)
  const navigate = useNavigate()
  const { address: account } = useAccount()
  const gameId = useParams().gameId
  const gameAvatarHref = findGameById(+gameId).backgroundImageUrl

  useEffect(() => {
    getInternalBalances()
    //eslint-disable-next-line
  }, [])

  const handleStartCompetition = async () => {
    try {
      await Parse.Cloud.run('start-playing', { preset_id: preset.id + '', wallet: account, balance: String(tokenBalance), selectedStableCoin: tokenBlockchainIndex })
      navigate('/active-competition')
    }
    catch (error) {
      setIsAllowedToStartNewCompetition(false)
      document?.getElementById('scroll-info')?.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" })
    }
  }

  const number = toWei((preset.get('feeInUSD') * 2).toString()) - toWei((preset.get('rakeInUsd') * 2).toString());
  const result = fromWei(number.toString());

  return (
    <>
      <div className="match-stats-widget match-stats-widget--general my-3">
        <ul className="match-stats-widget__body" id='list'>
          <li>
            <figure className="match-team match-team--winner" role="group">
              <figure
                className="match-team-logo match-team-logo--team-1"
                role="group">
                <img
                  style={{ width: '100px' }}
                  src={gameAvatarHref}
                  srcSet={gameAvatarHref}
                  alt="Logo"
                />
              </figure>
            </figure>
          </li>
          {/* <li className='py-2'>
            <span className='payment-title' > Your Address</span>
            <span className='font-weight-light'>
              [ <Hash hash={account} shorten={5} mode='address' /> ]
            </span>
          </li> */}
          <li className='py-2'>
            <span className='payment-title' >Competitors</span>
            <span>
              {preset.get('players')}v{preset.get('players')}
            </span>
          </li>
          <li className='py-2'>
            <span className='text-capitalize payment-title '>
              Competition Entry Fee
            </span>
            <span>
              {(preset.get('feeInUSD'))} USD
            </span>
          </li>
          <li className='py-2'>
            <span className='text-capitalize payment-title '>
              Admin Fee
            </span>
            <span>
              {(preset.get('rakeInUsd'))} USD
            </span>
          </li>
          <li className='py-2'>
            <span className='text-left text-capitalize payment-title' >
              compete to earn (after fees)
              <>
                <br />
                <span className='text-success text-capitalize payment-subtitle'  >
                  Amount of credits you will receive in your EOG Account if you win this competition
                </span>
              </>
            </span>
            <span  >
              {preset.get('rakeInUsd') ? result : (preset.get('feeInUSD') * 2)} USD
            </span>
          </li>
        </ul>
        <div className="mt-5 d-flex justify-content-center">
          <div />
          {
            isAllowedToStartNewCompetition ?
              (availableBalance >= preset.get('feeInUSD') ? <Button onClick={handleStartCompetition} className=" mt-1 text-center" > Confirm </Button>
                :
                <Link to='/my-account' className='link-light'> <u> Insufficient Balance </u> </Link>)
              :
              <>
                <p className='mt-3 text-white border border-warning p-3 '>
                  <p className='text-warning'>  Unable to join</p>
                  <p> You already Joined an active competition.</p>
                  <Link to='/active-competition' className='link-light'> <u> Go to the active competition </u> </Link>
                </p>
                <div id='scroll-info' />
              </>
          }
        </div>
      </div>
    </>
  )
}

const mapStateToProps = (state) => ({
  availableBalance: state.user.availableBalance,
})

const mapDispatchToProps = (dispatch) => ({
  getInternalBalances: () => dispatch(getInternalWalletBalances()),
})

export default connect(mapStateToProps, mapDispatchToProps)(StartCompetition)