import React, { useEffect, useState, useRef } from "react";
import Parse from "parse";
import { connect } from "react-redux";
import { GamesLayout } from "layouts";
import { Preloader } from "components";
import Pagination from "./Pagination";
import { Helmet } from "react-helmet";

const Transaction = Parse.Object.extend("Transaction");

function InternalWalletTransactionComponent(props) {
  const [transactions, setTransactions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [transactionsPerPage] = useState(12);
  const indexOfLastTransaction = currentPage * transactionsPerPage;
  const indexOfFirstTransaction = indexOfLastTransaction - transactionsPerPage;
  const currentTransactions = transactions?.slice(
    indexOfFirstTransaction,
    indexOfLastTransaction
  );
  const totalTransactions = transactions?.length;
  const urlRef = useRef();
  const { user } = props;

  useEffect(() => {
    generateExportTxUrl();
  }, []);

  useEffect(() => {
    getTransactionsByUser();
    //eslint-disable-next-line
  }, [user]);

  const getTransactionsByUser = async () => {
    const query = new Parse.Query(Transaction);
    query.equalTo("user", user);
    query.descending("createdAt");
    try {
      const result = await query.find();
      setTransactions(result);
    } catch (error) {
      console.log(error);
    }
  };

  const generateExportTxUrl = async (e) => {
    // setInterval(async () => {
    //   if (urlRef.current && urlRef.current.href === " " ) {
    //     try {
    //       const result = await Parse.Cloud.run("export-internal-wallet-transactions");
    //       urlRef.current.href = result._url;
    //       urlRef.current.setAttribute("download", "transaction.xlsx");
    //     } catch (error) {
    //       console.log(error);
    //     }
    //   }
    // }, 2000);
  };

  return (
    <GamesLayout>
      <Helmet>
        <title>EVOLVE | Transaction History</title>
      </Helmet>
      <>
        <div className="site-wrapper matches-table site-layout--default">
          <div className="site-overlay" />
          <main
            className="site-content site-content--center page d-flex  justify-content-center align-items-center"
            id="wrapper"
          >
            <div className="row text-center d-flex flex-column align-items-center justify-content-center">
              <section
                className="col-xs-12 col-xl-10 p-5 d-flex"
                id="transactionSection"
                style={{
                  backgroundColor: "#151720",
                  opacity: 0.9,
                  minHeight: "66vh",
                  height: "100%",
                  position: "relative",
                }}
              >
                <div
                  className="d-flex flex-column"
                  style={{
                    minHeight: "70vh",
                    width: "100%",
                  }}
                >
                  <h5 style={{ fontSize: "3rem", color: "#ccc" }}>
                    Transaction History
                  </h5>
                  {/* <div className="text-right text-white">
                    {transactions.length > 0 && (
                      <a
                        id="tx"
                        href=" "
                        download
                        ref={urlRef}
                        className="btn bg-transparent p-0"
                      >
                        <svg
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          width={30}
                          height={30}
                          fill="#CCCCCC"
                          viewBox="0 0 1000 1000"
                          enableBackground="new 0 0 1000 1000"
                        >
                          <metadata>
                            {" "}
                            Svg Vector Icons :
                            http://www.onlinewebfonts.com/icon{" "}
                          </metadata>
                          <g>
                            <path d="M552.8,147.1V10L25,153.7v692.7L552.8,990V852.9H975V147.1H552.8z M552.8,661.6h112.1v85.8H552.8V661.6z M552.8,523.1h112.1v85.8H552.8V523.1z M552.8,384.6h112.1v85.8H552.8V384.6z M552.8,246h112.1v85.8H552.8V246z M737.5,661.6h131.9v85.8H737.5V661.6z M737.5,523.1h131.9v85.8H737.5V523.1z M737.5,384.6h131.9v85.8H737.5V384.6z M737.5,246h131.9v85.8H737.5V246z M228.1,355.8l64.1,101.3l66.9-107.6l55.4-2.7l-93.4,148.4l100.1,159.6l-60.2-3l-70.5-116.4L219,644.9l-56.6-2.8l100.1-146.9l-93.4-136.6L228.1,355.8z" />
                          </g>
                        </svg>
                      </a>
                    )}
                  </div> */}
                  {currentTransactions.length > 0 ? (
                    <table
                      className="table table-striped text-transform-none"
                      id="transaction-table custom-scrollbar"
                      style={{
                        fontSize: "1.25rem",
                        color: "#ccc",
                        borderColor: "#ccc",
                      }}
                    >
                      <thead className="table-borderless">
                        <tr>
                          <th scope="col" className="text-center">Amount</th>
                          <th scope="col" className="text-center">Type</th>
                          <th scope="col" className="text-center">Date</th>
                          <th scope="col" className="text-center">Details</th>
                          <th scope="col" className="text-center">Transaction State</th>
                          <th scope="col" className="text-center">Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        <>
                          {currentTransactions.map((transaction) => {
                            const { amount, createdAt, type, status, details, transactionState } =
                              transaction.toJSON();
                            const defineTxType = () => {
                              if (type === "deposit") return true;
                              else if (type === "withdraw") return false;
                              else if (type === "startCompetition") return true;
                              else if (type === "finishCompetition") return false;
                              else if (type === "transfer") {
                                if (details?.senderUsername === user.get('username')) return true;
                                else return false;
                              }
                            };
                            const transactionType = defineTxType(type);
                            return (
                              <tr key={transaction.id}>
                                <td className="py-3 px-0 text-success font-16 text-center">
                                  {amount} USD{" "}
                                  {transactionType ? (
                                    <svg
                                      id="_1-Arrow_Up"
                                      data-name="1-Arrow Up"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 32 32"
                                      fill="#CCCCCC"
                                      width={15}
                                      height={15}
                                    >
                                      <title>1-Arrow Up</title>
                                      <path d="M26.71,10.29l-10-10a1,1,0,0,0-1.41,0l-10,10,1.41,1.41L15,3.41V32h2V3.41l8.29,8.29Z" />
                                    </svg>
                                  ) : (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 32 32"
                                      fill="#CCCCCC"
                                      width={15}
                                      height={15}
                                    >
                                      <path
                                        d="M26.29 20.29 18 28.59V0h-2v28.59l-8.29-8.3-1.42 1.42 10 10a1 1 0 0 0 1.41 0l10-10z"
                                        data-name="2-Arrow Down"
                                      />
                                    </svg>
                                  )}
                                </td>
                                <td className="font-16">
                                  <u>
                                    {type}
                                  </u>
                                </td>
                                <td className="font-16">
                                  <u>
                                    {new Date(createdAt).toLocaleString()}
                                  </u>
                                </td>
                                <td className="font-16">
                                  <u>
                                    {details ?
                                      <>
                                        <p>
                                          {details?.senderUsername ? `Sender: ${details?.senderUsername}` : ""}
                                        </p>
                                        <p>
                                          {details?.recipientUsername ? `Recipient: ${details?.recipientUsername}` : ""}
                                        </p>
                                      </>
                                      : "N/A"
                                    }
                                  </u>
                                </td>
                                <td className="overflow-hidden font-16">
                                  <u>
                                    {transactionState}
                                  </u>
                                </td>
                                <td className="overflow-hidden font-16">
                                  <u>
                                    {status}
                                  </u>
                                </td>
                                <td
                                  style={{ textTransform: "none" }}
                                  className="font-16 text-left"
                                >
                                </td>
                              </tr>
                            );
                          })}
                        </>
                      </tbody>
                    </table>
                  ) : (
                    <>
                      <table
                        className="table table-striped text-transform-none"
                        id="transactionTable"
                        style={{
                          fontSize: "1.25rem",
                          color: "#ccc",
                          borderColor: "#ccc",
                        }}
                      >
                        <thead className="table-borderless">
                          <tr>
                            <th scope="col" className="text-center">Amount</th>
                            <th scope="col" className="text-center">Type</th>
                            <th scope="col" className="text-center">Date</th>
                            <th scope="col" className="text-center">Details</th>
                            <th scope="col" className="text-center">Status</th>
                          </tr>
                        </thead>
                      </table>
                      <p
                        className="mt-3"
                        style={{ letterSpacing: 1.3, textTransform: "none" }}
                      >
                        Looks like you don't have any transactions yet.
                      </p>
                    </>
                  )}

                  <div className="w-100 d-flex h-100 align-items-end">
                    <div className="justify-content-center w-100">
                      <Pagination
                        setCurrentPage={(currentPage) =>
                          setCurrentPage(currentPage)
                        }
                        transactionsPerPage={transactionsPerPage}
                        totalTransactions={totalTransactions}
                        currentPage={currentPage}
                      />
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </main>
        </div>
      </>
      <Preloader />
    </GamesLayout>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.user.userParseObject,
  };
};

const InternalWalletTransaction = connect(mapStateToProps)(InternalWalletTransactionComponent);
export { InternalWalletTransaction };
