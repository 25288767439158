import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

function UserMenuComponent(props) {
  const { username } = props

  return (
    <li >
      <a href=' ' onClick={(e) => { e.preventDefault() }} className='user-menu-arrow'>{username ? username : '...'} </a>
      <ul className="main-nav__sub">
        <li >
          <Link to={'/profile/account-status'}>
            Profile
          </Link>
        </li>
        <li >
          <Link to={'/my-account'}>
            My Account
          </Link>
        </li>
        {/* {swapEnabled && <li >
          <Link to={'/transactions'}>
            Transactions
          </Link>
        </li>} */}
        {/* <li >
          <Link to={'/airdropped-tokens'}>
            Airdropped Tokens
          </Link>
        </li> */}
        <li >
          <Link to={'/logout'}>
            LogOut
          </Link>
        </li>
      </ul>
    </li>
  )
}

const mapStateToProps = (state) => ({
  swapEnabled: state.config?.['ico-swap-enabled']
})

const UserMenu = connect(mapStateToProps)(UserMenuComponent);
export { UserMenu }
