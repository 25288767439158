import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Dropdown } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { generateAvatar } from 'utils/helper'

function AccountUserAvatarComponent(props) {
  const { userName, userAvatarUrl, fetchedUser } = props
  useEffect(() => {
    //eslint-disable-next-line
  }, [userName, userAvatarUrl])

  return (
    <div className='d-flex align-items-center ml-2'>
      <div className="header-account__custom mr-2 ">
        <div className="header-account__name font-weight-bold "> {userName}
        </div>
      </div>
      <Dropdown>
        <Dropdown.Toggle className='bg-transparent p-0 shadow-none'>

          {userAvatarUrl ? <img
            src={userAvatarUrl}
            srcSet={userAvatarUrl}
            alt='User Avatar'
            className='w-40px'
          />
            :
            <img
              className='bg-white'
              src={generateAvatar(fetchedUser)}
              alt='User Avatar'
            />
          }
        </Dropdown.Toggle>
        <Dropdown.Menu className='rounded-0 mb-3  link-profile-wrapper shadow-lg'>
          <Link to="/profile/account-status" className=' d-block text-white text-center p-3 link-profile'>Go to Profile</Link>
          <Link to="/my-account" className=' d-block text-white text-center p-3 link-profile'>Go to My Account</Link>
          {/* <Link to="/transactions" className=' d-block text-white text-center p-3 link-profile'>Transactions</Link> */}
          <Link to="/logout" className=' d-block text-white text-center p-3 link-profile' >Log out</Link>
        </Dropdown.Menu>
      </Dropdown>
    </div >
  )
}

const mapStateToProps = (state) => ({
  userName: state.user.username,
  userAvatarUrl: state.user.userAvatarUrl,
  userParseObject: state.user.userParseObject,
  fetchedUser: state.user.userParseObject
})

const mapDispatchToProps = (dispatch) => ({
})

const AccountUserAvatar = connect(mapStateToProps, mapDispatchToProps)(AccountUserAvatarComponent)
export { AccountUserAvatar }
