import React, { useEffect, useRef, useState } from 'react'
import Parse from 'parse'
import { getInternalWalletBalances } from 'redux/actions/userActions'
import { connect } from 'react-redux'
import { Button } from 'components';
import { customToast } from 'utils/helper';
import { InternalWalletBalances } from './InternalWalletBalance';
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';

function TransferComponent(props) {
  const { availableBalance, pendingBalance, inCompetitionBalance, userHasWallet, getInternalBalances } = props
  const [amount, setAmount] = useState(null);
  const [username, setUsername] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.value = availableBalance;
      inputRef.current.focus();
    }
  }, [availableBalance, pendingBalance, inCompetitionBalance])


  const handleTransferClick = async () => {
    if (!amount) return customToast('Please enter amount', 'danger')
    if (!username) return customToast('Please enter recipient username', 'danger')

    setIsLoading(true)
    try {
      await Parse.Cloud.run('transfer', { amount: Number(amount), recipientUsername: username })
      getInternalBalances()
      setAmount(null)
      customToast('Transfer successful', 'success')
    } catch (error) {
      customToast(error.message, 'danger')
      console.log(error)
    }
    setIsLoading(false)
  }

  return (
    <div>
      {
        userHasWallet ?
          <div className="deposit-container">
            <InternalWalletBalances availableBalance={availableBalance} pendingBalance={pendingBalance} inCompetitionBalance={inCompetitionBalance} />
            <p className='transfer-input-label'>Enter the amount you want to transfer</p>
            <div className="transfer-input-container">
              <input type="number" className='custom-transfer-input' value={amount || ''} onChange={(e) => setAmount(e.target.value)} />
            </div>

            <p className='transfer-input-label mt-2'>Enter recipient username </p>
            <div className="transfer-input-container">
              <input type="email" className='custom-transfer-input' onChange={(e) => setUsername(e.target.value)} />
            </div>
            <TransferConfirmModal
              disabled={(amount > availableBalance || !availableBalance || !amount || !username || isLoading) ? true : false}
              availableBalance={availableBalance}
              amount={amount}
              username={username}
              isLoading={isLoading}
              handleTransferClick={handleTransferClick}
            />
            <p className="text-white font-16">Go to <Link to={'/my-account/transactions'}>Transaction history</Link> </p>
          </div>
          :
          <div className="Deposit__no-wallet">
            <p>You don't have a wallet</p>
          </div>
      }
    </div>

  );
}

const mapStateToProps = (state) => ({
  userHasWallet: state.user.userInternalWallet,
  availableBalance: state.user.availableBalance,
  pendingBalance: state.user.pendingBalance,
  inCompetitionBalance: state.user.inCompetitionBalance,
})

const mapDispatchToProps = (dispatch) => ({
  getInternalBalances: () => dispatch(getInternalWalletBalances())
})

const Transfer = connect(mapStateToProps, mapDispatchToProps)(TransferComponent)
export { Transfer }


function TransferConfirmModal({ openModal, ...props }) {
  const { availableBalance, amount, isLoading, disabled } = props
  const [modalShow, setModalShow] = useState(false)
  useEffect(() => {
    setModalShow(openModal)
  }, [openModal])

  return (
    <>
      <Button className="withdraw-btn" onClick={() => setModalShow(true)} disabled={disabled}>
        {
          isLoading ? 'Loading...' :
            amount > availableBalance ? 'Insufficient funds' : 'Transfer'
        }
      </Button>
      <ConfirmModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        {...props}
      />
    </>
  )
}

function ConfirmModal(props) {
  const { amount, username, isLoading, handleTransferClick, disabled } = props
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      contentClassName="bg-game-dark p-1"
      centered>
      <Modal.Header className={`btn text-right text-white  cursor-default `}>
        <button
          className=" text-center border-0 "
          style={{ backgroundColor: '#5e627e' }}
          onClick={props.onHide}>
          <span className="font-weight-bold">×</span>
        </button>
      </Modal.Header>
      <Modal.Body className="d-flex justify-content-center pt-0">
        <main className=" site-content text-center col-10 mb-3">
          <div className="site-content__center ">
            <h4 className="text-warning mt-0 pb-3 pt-0">Transfer details</h4>
            <p className="text-white mt-5">
            </p>
            <div className='text-white' />
            <p className="text-white font-20 font-weight-bold mt-5">
              You are about to initiate a transfer of funds from your account to the designated recipient's account. Please review the following details carefully before proceeding:
              <div className="match-stats-widget match-stats-widget--general my-3">
                <ul className="match-stats-widget__body mt-5" id='list'>
                  <li className='py-2'>
                    <span className='payment-title' >Amount</span>
                    <span>
                      {amount} USD
                    </span>
                  </li>
                  <li className='py-2'>
                    <span className='text-capitalize payment-title '>
                      Recipient Username
                    </span>
                    <span>
                      {username}
                    </span>
                  </li>
                </ul>
              </div>
            </p>
            <Button className="withdraw-btn" onClick={handleTransferClick} disabled={disabled}>
              {
                isLoading ? 'Loading...' :
                  'Confirm'
              }
            </Button>
          </div>
        </main>
      </Modal.Body>
    </Modal>
  )
}