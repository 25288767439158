import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { getInternalWalletBalances } from 'redux/actions/userActions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoins } from '@fortawesome/free-solid-svg-icons'
import { Button } from 'components';
import Parse from 'parse';
import { customToast } from 'utils/helper';
import { InternalWalletBalances } from './InternalWalletBalance';
import { Link } from 'react-router-dom';

function DepositComponent(props) {
    const { availableBalance, pendingBalance, inCompetitionBalance, userHasWallet, getInternalBalances  } = props
    const [amount, setAmount] = useState(null);
    const [customDeposit, setCustomDeposit] = useState(false);
    const [loading, setLoading] = useState(false);

    const AMOUNTS = [10, 50, 100]

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [availableBalance, pendingBalance, inCompetitionBalance])


    const handleDepositClick = async () => {
        setLoading(true);
        try {
            await Parse.Cloud.run('deposit', { amount: Number(amount) })
            // set balance to redux
            getInternalBalances();
            setAmount(null);
            setCustomDeposit(false);
            customToast('Deposit successful', 'success')
        } catch (error) {
            console.log(error)
            customToast(error.message, 'danger')
        }
        setLoading(false);
    };

    return (
        <div>
            {
                userHasWallet ?
                    <div className="deposit-container">
                        <InternalWalletBalances availableBalance={availableBalance} pendingBalance={pendingBalance} inCompetitionBalance={inCompetitionBalance} />
                        {!customDeposit && (
                            <div className="amount-options">
                                {AMOUNTS.map((amt) => (
                                    <button key={amt} className="amount-btn" onClick={() => setAmount(amt)}>
                                        {amt}
                                        &nbsp; &nbsp; <FontAwesomeIcon icon={faCoins} size="lg" className='pb-2' />
                                    </button>
                                ))}
                                <button className="amount-btn" onClick={() => setCustomDeposit(true)}>
                                    CUSTOM &nbsp; &nbsp; <FontAwesomeIcon icon={faCoins} size="lg" className='pb-2' />
                                </button>
                            </div>
                        )}
                        {customDeposit && (
                            <div className="custom-amount">
                                <input type="number" className='custom-deposit-input' value={amount || ''} onChange={(e) => setAmount(e.target.value)} />
                                <Button className="cancel-btn btn-danger" onClick={() => setCustomDeposit(false)}>GO BACK</Button>
                            </div>
                        )}
                        <Button className="deposit-btn" onClick={handleDepositClick} disabled={!amount || loading}>
                            {loading ? 'Loading...' : `Deposit ${amount ? ` $${amount}` : ''} `}
                        </Button>
                        <p className="text-white font-16">Go to <Link to={'/my-account/transactions'}>Transaction history</Link> </p>
                    </div>
                    :
                    <div className="Deposit__no-wallet">
                        <p>You don't have a wallet</p>
                    </div>
            }
        </div>
    );
}

const mapStateToProps = (state) => ({
    userHasWallet: state.user.userInternalWallet,
    availableBalance: state.user.availableBalance,
    pendingBalance: state.user.pendingBalance,
    inCompetitionBalance: state.user.inCompetitionBalance
})

const mapDispatchToProps = (dispatch) => ({
    getInternalBalances: () => dispatch(getInternalWalletBalances())
})

const Deposit = connect(mapStateToProps, mapDispatchToProps)(DepositComponent)
export { Deposit }
