import * as actions from '../actions';


const initState = {
  userAccount: '',
  userBalance: '',
  username: '',
  userAvatarUrl: '',
  userParseObject: null,
  localUserObject: null,
  hasSufficientStableCoin: null,
  userIcoEnabled: null,
  userGamingEnabled: null,
  userSwapEnabled: null,
  userFiatEnabled: null,
  userWireEnabled: null,
  isUserWalletApproved: null,
  presetID: null,
  userInternalWallet: true,
  availableBalance: 0,
  pendingBalance: 0,
  inCompetitionBalance: 0,
}

export const userReducer = (state = initState, action) => {
  switch (action.type) {
    case actions.FETCH_CURRENT_USER:
      return {
        ...state,
        ...action.payload
      }
    case actions.FETCH_CURRENT_USER_ERROR:
      return {
        ...state,
        ...action.payload
      }
    case actions.GET_USER_BALANCE:
      return {
        ...state,
        ...action.payload
      }
    case actions.GET_USER_BALANCE_ERROR:
      return {
        ...state,
        ...action.payload
      }
    case actions.SET_USER_ACCOUNT_ADDRESS:
      return {
        ...state,
        ...action.payload
      }
    case actions.SET_USER_ACCOUNT_ADDRESS_ERROR:
      return {
        ...state,
        ...action.payload
      }
    case actions.GET_USER_ACCOUNT_ADDRESS:
      return {
        ...state,
        ...action.payload
      }
    case actions.GET_USER_ACCOUNT_ADDRESS_ERROR:
      return {
        ...state,
        ...action.payload
      }
    case actions.SET_HAS_SUFFICIENT_STABLE_COIN:
      return {
        ...state,
        ...action.payload
      }
    case actions.SET_APPROVED_TOKENS:
      return {
        ...state,
        ...action.payload
      }
    case actions.SET_USER_WALLET_APPROVAL:
      return {
        ...state,
        ...action.payload
      }
    case actions.SET_USER_WALLET_APPROVAL_ERROR:
      return {
        ...state,
        ...action.payload
      }
    case actions.SET_PRESET_ID:
      return {
        ...state,
        ...action.payload,
      };
    case actions.SET_USER_INTERNAL_WALLET:
      return {
        ...state,
        ...action.payload,
      };
    case actions.GET_INTERNAL_WALLET_BALANCE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state
  }
}

